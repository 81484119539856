<template>
  <div>
    <slideshow-module :slides="slides"></slideshow-module>
    <text-module>
      <br />
      <div style="display: block; margin: 0px 10px 20px; color: #888888">
        <!-- UniverseTheGame/images/pic5D78B0A3BDA580A8757BD848F0B38FEB.png -->
        <img
          style="padding-top: 40px"
          src="@/assets/the-game/story-maelstrom.png"
          align="right"
        />
        <h2 style="font-size: 24px">A compelling story</h2>
        Picture four Minifigures on a daring quest to find the Imagination
        Nexus— the only source of Pure Imagination in the known universe!
        <br />
        <br />
        The adventurers are Baron Typhonus, scholar and tycoon; Doctor
        Overbuild, construction specialist; Duke Exeter, legendary warrior; and
        Hael Storm, infamous pirate.<br />
        <br />
        A dangerous journey leads the team to a strange planet called Crux,
        where they discover the Imagination Nexus! Greedy for its creative
        powers, the Baron gets pulled into the source of Pure Imagination!<br />
        <br />
        A dark Maelstrom of chaos energy is unleashed! Crux gets blown to pieces
        and the Maelstrom threatens to consume the entire universe!
        <br />
        <br />
        Amid the rubble, Doctor Overbuild finds a small sliver of hope: one tiny
        shard of Pure Imagination! <br />
        <br />
        Vowing to defend imagination from all evil, Doc, Duke and Hael form the
        Nexus Force! Soon they add a fourth member to match the Maelstrom's
        growing power: a secretive ninja sorceress named Vanda Darkflame. <br />
        <br />
        Now the Nexus Force calls upon every available Minifigure to join them
        in the battle to save imagination!
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import { SlideOptions } from "@/options";
const SLIDES: SlideOptions[] = [
  {
    // download52B6E7A9548AF003C810E72501BD0473.swf
    img: require("@/assets/slideshow-items/story-movie.jpg"),
    video: require("@/assets/slideshow-items/story.mp4"),
    videoPos: "C",
    headline2: "Preview the story!",
    text1:
      "The LEGO® Universe story sets the scene for the game--including how the dark and destructive Maelstrom was unleashed!",
    // link=<a href="http://universe.lego.com/en-us/media/default.aspx?section=141010&category=141100&contentid=197099">Watch the video</a>
    link: "/media/s/141010/c/141100/id/197099",
    linkText: "Watch the video",
  },
];

export default {
  data() {
    return {
      slides: SLIDES,
    };
  },
};
</script>