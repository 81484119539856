
import { SlideOptions } from "@/options";
const SLIDES: SlideOptions[] = [
  {
    // download52B6E7A9548AF003C810E72501BD0473.swf
    img: require("@/assets/slideshow-items/story-movie.jpg"),
    video: require("@/assets/slideshow-items/story.mp4"),
    videoPos: "C",
    headline2: "Preview the story!",
    text1:
      "The LEGO® Universe story sets the scene for the game--including how the dark and destructive Maelstrom was unleashed!",
    // link=<a href="http://universe.lego.com/en-us/media/default.aspx?section=141010&category=141100&contentid=197099">Watch the video</a>
    link: "/media/s/141010/c/141100/id/197099",
    linkText: "Watch the video",
  },
];

export default {
  data() {
    return {
      slides: SLIDES,
    };
  },
};
